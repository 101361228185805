var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-3 mt-2"},[(_vm.processing)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"65vh"}},[_c('Spinner')],1):_c('div',{staticClass:"content-area"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
        enabled: true,
        perPage: 8,
      },"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'username')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.username))])]):_vm._e(),(props.column.field == 'email')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.email))])]):_vm._e(),(props.column.field == 'country')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.country))])]):_vm._e(),(props.column.field == 'gender')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.gender))])]):_vm._e(),(props.column.field == 'dataset_count')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.dataset_count))]),_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],staticClass:"dataset-icon",staticStyle:{"float":"right","font-weight":"bold","font-size":"1.3rem"},attrs:{"icon":"plus","variant":"success"},on:{"click":function($event){(_vm.currentDatasets = props.row.datasets),
                (_vm.currentUser = props.row.id)}}})],1):_vm._e()]}}])})],1),_c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Modify Datasets","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleDatasetUpdate}},[_vm._v("update")])]}}])},[(_vm.deletingSelected)?_c('b-nav',[_c('b-nav-item',[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1):_vm._e(),_c('b-list-group',[_vm._l((_vm.currentDatasets),function(dataset){return _c('b-list-group-item',{key:dataset.id},[_vm._v(_vm._s(dataset.name)),_c('b-icon',{staticClass:"delete-dataset",staticStyle:{"float":"right"},attrs:{"variant":"danger","icon":"trash"},on:{"click":function($event){return _vm.handleDeleteFromList(_vm.$emit, dataset.id)}}})],1)}),_c('p',{staticClass:"text-info mt-2 mb-0"},[_vm._v("Select dataset to add")]),_c('b-form-select',{staticClass:"mb-3",attrs:{"options":_vm.options,"value-field":"id","text-field":"name","disabled-field":"notEnabled","aria-placeholder":"Select dataset to add"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }